import React from 'react';
import './App.css';
import Bridge from './components/Bridge';

function App() {
  return (
    <div className="App">
      <Bridge />
    </div>
  );
}

export default App;
